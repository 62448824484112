import React from "react";
import gif2 from "./FIF.webp";
import gif3 from "./4.webp";
import gif4 from "./5.webp";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { StaticImage } from "gatsby-plugin-image";

const block = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
};

const text = {
  width: "40%",
  fontSize: "20px",
};

const img = {
  width: "100%",
  borderRadius: "10px",
  objectFit: "cover",
  maxHeight: "350px",
};

const isBrowser = typeof window !== "undefined";

function reveal() {
  var reveals = document.querySelectorAll(".reveal");

  for (var i = 0; i < reveals.length; i++) {
    var windowHeight = window.innerHeight;
    var elementTop = reveals[i].getBoundingClientRect().top;
    var elementVisible = 150;

    if (elementTop < windowHeight - elementVisible) {
      reveals[i].classList.add("active");
    } else {
      reveals[i].classList.remove("active");
    }
  }
}

if (isBrowser) {
  window.addEventListener("scroll", reveal);
}

const Rules = () => {
  return (
    <Container maxWidth={"lg"}>
      <Grid
        rowSpacing={5}
        container
        className="container"
        marginTop={2}
        marginBottom={2}
      >
        <Grid
          container
          xs={12}
          justifyContent={"center"}
          textAlign={"center"}
          flexDirection={"column"}
        >
          <h1>
            The go-to choice for{" "}
            <b style={{ color: "#FFA100" }}>professional-grade</b> capture.
          </h1>
          <div style={{ fontSize: "20px" }}>
            Perfect for ophthalmologists who demand the best visual <br />
            fidelity when recording low light procedures.
          </div>
        </Grid>
        <Grid container item style={block}>
          <Grid
            item
            xs={10}
            sm={5}
            style={text}
            marginTop={{ xs: 2, sm: 0 }}
            order={{ xs: 2, sm: 1 }}
          >
            <h4 style={{ marginTop: "0", color: "#1a202c" }}>
              Compatible with any:
            </h4>
            <Grid container alignItems={"center"}>
              <StaticImage
                src="./camera.svg"
                style={{ height: "40px" }}
                imgStyle={{ objectFit: "contain" }}
                loading="lazy"
                placeholder="dominantColor"
                formats={["auto", "webp", "avif"]}
              />
              <div style={{ marginLeft: "1em" }}>
                Full-frame <br /> E-mount cameras
              </div>
            </Grid>
          </Grid>{" "}
          <Grid item xs={10} sm={5} order={{ xs: 1, sm: 2 }}>
            <img style={img} src={gif2}></img>
          </Grid>
        </Grid>

        <Grid container item style={block} marginTop={2} marginBottom={2}>
          <Grid item xs={10} sm={5}>
            <img
              style={img}
              src={gif3}
              alt='Close-up image of a human eye captured through a slit lamp, showing detailed texture and clarity of the cornea and iris. The image includes reflections of light and fine eyelashes, with the text "RECORDED WITH Microto4K" displayed at the bottom. '
            ></img>
          </Grid>
          <Grid item xs={10} sm={5} style={text} marginTop={{ xs: 2, sm: 0 }}>
            <hr
              style={{
                width: "10%",
                margin: "0",
                height: "2px",
                backgroundColor: "rgb(255, 161, 0)",
                marginBottom: "2em",
              }}
            ></hr>
            <h2 style={{ marginTop: "0", color: "#1a202c" }}>
              Optimized for Low Light Environments{" "}
            </h2>
            <div>
              Retinal surgeries, for example, often present lighting challenges,
              but MicroTo4K's design, optimized for full-frame cameras,{" "}
              <b style={{ color: "#FFA100" }}>
                captures more light than ever before
              </b>
              . This means{" "}
              <b style={{ color: "#FFA100" }}>
                less noise and higher sensitivity
              </b>
              , providing clear, detailed recordings even in dim conditions.
            </div>
          </Grid>
        </Grid>

        <Grid container item style={block} marginTop={2} marginBottom={2}>
          <Grid
            item
            xs={10}
            sm={5}
            style={text}
            marginTop={{ xs: 2, sm: 0 }}
            order={{ xs: 2, sm: 1 }}
          >
            <hr
              style={{
                width: "10%",
                margin: "0",
                height: "2px",
                backgroundColor: "rgb(255, 161, 0)",
                marginBottom: "2em",
              }}
            ></hr>

            <h2 style={{ marginTop: "0", color: "#1a202c" }}>
              Ultra-Clear 4K Resolution
            </h2>
            <div>
              Present your work with <b>stunning clarity</b>. The MicroTo4K
              ensures your surgical recordings are captured in crystal-clear 4K
              resolution, bringing <b>every detail of your procedure to life</b>
              .
            </div>
            <div>
              Whether you're preparing for a conference presentation or
              reviewing complex surgeries,{" "}
              <b style={{ color: "#FFA100" }}>
                MicroTo4K delivers unmatched image sharpness and accuracy.
              </b>
            </div>
          </Grid>{" "}
          <Grid item xs={10} sm={5} order={{ xs: 1, sm: 2 }}>
            <img
              style={img}
              src={gif4}
              alt='Close-up image of the human retina captured through a slit lamp, showing detailed blood vessels radiating from the optic disc in vivid red and orange tones. A bright reflection of light is present near the center, with the text "RECORDED WITH Microto4K" displayed at the bottom. '
            ></img>
          </Grid>
        </Grid>
        <Grid container item style={block} marginTop={2} marginBottom={2}>
          <Grid item xs={10} sm={5}>
            <StaticImage
              src="../../images/gifs/cover.webp"
              style={{
                width: "100%",
                borderRadius: "10px",
                objectFit: "cover",
                maxHeight: "350px",
              }}
              imgStyle={{ objectFit: "contain" }}
              loading="lazy"
              placeholder="dominantColor"
              formats={["auto", "webp", "avif"]}
            />
          </Grid>
          <Grid item xs={10} sm={5} style={text} marginTop={{ xs: 2, sm: 0 }}>
            <hr
              style={{
                width: "10%",
                margin: "0",
                height: "2px",
                backgroundColor: "rgb(255, 161, 0)",
                marginBottom: "2em",
              }}
            ></hr>

            <h2 style={{ marginTop: "0", color: "#1a202c" }}>
              Engineered for Excellence{" "}
            </h2>
            <div>
              Every aspect of the MicroTo4K was designed to ensure that it
              provides the{" "}
              <b style={{ color: "#FFA100" }}>best imaging performance</b>.
            </div>
            <div>
              Its optical system is uniquely crafted to match Sony cameras
              perfectly, ensuring that every pixel counts when it comes to
              capturing your most critical moments.
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Rules;
