import React, { useEffect } from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Gallery from "../components/micro-to-4k/youtube";
import Rules from "../components/micro-to-4k/rules";
import Testimonials from "../components/micro-to-4k/testimonials";
import Seo from "../components/layout/seo";
import Subscribe from "../components/misc/check_list";
import "../static/mainPage.css";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { StaticImage } from "gatsby-plugin-image";
import { Grid } from "@mui/material";

let cs_theme = createTheme({
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = (
    <StaticImage
      src="../images/iso_color.png"
      alt="Logo"
      loading="eager"
      formats={["auto", "webp", "avif"]}
    />
  );
  return (
    <ThemeProvider theme={cs_theme}>
      <Layout location={location} title={siteTitle}>
        <Seo
          title="MicroTo4k | Custom Surgical"
          description="Custom Surgical is democratizing hardware and software to record microscope procedures easily and quickly without any professional engineer to attach or cables to share live."
        />
        <Subscribe postName={"main_page"} />
        <div className="image-container">
          <StaticImage
            src="../images/gifs/cover.webp"
            alt="Main Image"
            loading="eager"
            placeholder="blurred"
            formats={["auto", "webp", "avif"]}
            quality={100}
            style={{
              objectFit: "cover",
              aspectRatio: "2 / 1",
              width: "100%",
              height: "auto",
              height: "80vh",
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "center",
              backgroundSize: "cover",
              width: "100%",
            }}
          />{" "}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.4)",
            }}
          ></div>
          <div className="text-overlay">
            <Grid container sx={{ display: "block" }} className="text_over">
              <b>MicroTo4K</b>
              <Grid item xs={10} sm={8} md={8}>
                <div style={{ marginTop: "1rem", fontSize: "25px" }}>
                  The
                  <span
                    style={{
                      color: "#FFA100",
                      marginLeft: "5px",
                      fontWeight: "bold",
                    }}
                  >
                    best image {""}
                  </span>
                  quality on the market
                </div>
              </Grid>
              <button className="shop" style={{ marginTop: "5%" }}>
                <a
                  href="/store/micro-to-4k"
                  style={{ color: "white", fontSize: "24px" }}
                >
                  Shop now
                </a>
              </button>
            </Grid>
          </div>
        </div>
        <Rules
          style={{ scrollSnapStop: "always", scrollSnapType: "y mandatory" }}
        />
        <div style={{ marginBottom: "5%" }}></div>
        <Gallery />
        {/* <div style={{ marginBottom: "5%" }}></div> */}
        {/* <Testimonials /> <div style={{ marginBottom: "5%" }}></div> */}
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;
